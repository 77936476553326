import React from 'react';
import Layout from '../templates/layout';
import BasePageContent from '../components/utils/BasePageContent';
import Footer from '../components/Footer';
import { Box } from '@mui/system';
import Seo from '../components/utils/Seo';

const rulesUrl = '/[FiberPay]_Regulamin_Zapłatomat_20230919.pdf';

const Regulations = () => {
  return (
    <>
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Layout>
            <BasePageContent title='Regulamin' centered='true'>
              <Box>
                <a download href={rulesUrl} className='link-inline'>
                  Pobierz (PDF)
                </a>
              </Box>
            </BasePageContent>
          </Layout>
        </Box>
        <Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export const Head = ({ location }) => (
  <Seo
    location={location}
    title='Regulamin'
    description='Zapoznaj się z aktualnym Regulaminem serwisu Zapłatomat. Zakładając konto akceptujesz jego warunki'
  />
);

export default Regulations;
